import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

// Helper function to get today and tomorrow's date
function getTodayAndTomorrow() {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)
  return [
    today.toISOString().split('T')[0], // Format as "YYYY-MM-DD"
    tomorrow.toISOString().split('T')[0]
  ]
}

// Helper function to get today and tomorrow's date as a single string
function getTodayToTomorrowString() {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  // Format dates as "YYYY-MM-DD"
  const todayStr = today.toISOString().split('T')[0]
  const tomorrowStr = tomorrow.toISOString().split('T')[0]

  return `${todayStr} to ${tomorrowStr}`
}

export default {
  namespaced: true,
  state: {
    analytics: null,
    rangeDate: getTodayToTomorrowString(),
    calendarOptions: [
      {
        color: 'primary',
        label: '[ A.1 ] Tamu ( Visitor ) - Umum'
      },
      {
        color: 'danger',
        label: '[ A.2 ] Awak 2 Truck'
      },
      {
        color: 'success',
        label: '[ B ] Identitas / Karyawan / Rutinitas'
      },
      {
        color: 'warning',
        label: '[ C ] Pekerja Harian'
      },
      {
        color: 'info',
        label: '[ D ] Pengurus Agent BBM'
      },
      {
        color: 'info',
        label: '[ E ] Jalur Laut ( Crew Kapal )'
      }
    ],
    selectedCalendars: [
      '[ A.1 ] Tamu ( Visitor ) - Umum',
      '[ A.2 ] Awak 2 Truck',
      '[ B ] Identitas / Karyawan / Rutinitas',
      '[ C ] Pekerja Harian',
      '[ D ] Pengurus Agent BBM',
      '[ E ] Jalur Laut ( Crew Kapal )'
    ]
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_RANGE_DATE(state, val) {
      state.rangeDate = val
    },
    SET_ANALYTICS(state, val) {
      state.analytics = val
    }
  },
  actions: {
    // fetchEvents(ctx, { calendars }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/calendar/events', {
    //         params: {
    //           calendars: calendars.join(',')
    //         }
    //       })
    //       .then((response) => {
    //         console.log('response: ', response)
    //         return resolve(response)
    //       })
    //       .catch((error) => reject(error))
    //   })
    // },
    fetchEvents(ctx, { calendars, rangeDate }) {
      return new Promise((resolve, reject) => {
        useJwt.http
          .get('vw_visitor_planning_area_land', {
            params: {
              calendars: calendars.join(','),
              rangeDate: rangeDate
            }
          })
          .then((response) => {
            console.log('response: ', response)
            // Commit the mutation to set the analytic data
            ctx.commit('SET_ANALYTICS', response.data.analytics) // Assuming response.data is the analytic data you want to set

            return resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    }
  }
}
