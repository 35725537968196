<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        />
      </div>
      <div
        v-if="isLoadingFetch"
        style="position: fixed; z-index: 99; width: 86%"
      >
        <b-progress
          animated
          show-progress
          variant="primary"
          :max="100"
          height="1.3rem"
          :striped="true"
          :class="'progress-bar-info'"
        >
          <b-progress-bar
            :value="100"
            :label="`Memuat data planning . . .`"
            variant="primary"
          />
        </b-progress>
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
              <!-- <template v-slot:eventContent="arg">
                <b>{{ arg.event.title }}</b>
              </template> -->
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted, watch } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler
  },
  // planning-darat
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isLoadingFetch
    } = useCalendar()
    const { watchNotification } = useAppConfig()

    fetchEvents()

    // Watch for notification and trigger re-fetch based on conditions
    watch(
      () => watchNotification.value, // watch the reactive 'watchNotification' value
      (newNotification) => {
        console.log('[0] anIncomingNotification ::', newNotification)
        try {
          // Assuming the notification has a `data` property where the screen re-fetch info is
          const highLightData = newNotification?.data?.screen_re_fetch

          // Define the conditions for refresh
          const willBeRefreshed = ['in-area-darat', 'planning-darat']
          const goRefreshLand = willBeRefreshed.some((data) =>
            highLightData?.includes(data)
          )

          console.log('goRefreshLand ::', goRefreshLand)

          // If conditions met, trigger fetchEvents
          if (goRefreshLand) {
            console.log('Triggering fetchEvents due to notification')
            // fetchEvents() // Trigger re-fetch for events
            refetchEvents()
          }
        } catch (error) {
          console.error('Error handling notification re-fetch', error)
        }
      },
      { immediate: true } // Run the watcher immediately on initialization
    )

    console.log('calendarOptions', calendarOptions)

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isLoadingFetch,
      watchNotification
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';
</style>
