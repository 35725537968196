import { v4 as uuidv4 } from 'uuid'
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    '[ A.1 ] Tamu ( Visitor ) - Umum': 'primary',
    '[ A.2 ] Awak 2 Truck': 'danger',
    '[ B ] Identitas / Karyawan / Rutinitas': 'success',
    '[ C ] Pekerja Harian': 'warning',
    '[ D ] Pengurus Agent BBM': 'info',
    '[ E ] Jalur Laut ( Crew Kapal )': 'info'
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: ''
    }
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (
    updatedEventData,
    propsToUpdate,
    extendedPropsToUpdate
  ) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success'
      }
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, {
      allDay: updatedEventData.allDay
    })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(
        propName,
        updatedEventData.extendedProps[propName]
      )
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = (eventId) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed ( Coming Soon )',
        icon: 'TrashIcon',
        variant: 'danger'
      }
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = (eventApi) => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description },
      allDay
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description
      },
      allDay
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = (eventData) => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = (eventData) => {
    store
      .dispatch('calendar/updateEvent', { event: eventData })
      .then((response) => {
        const updatedEvent = response.data.event

        const propsToUpdate = ['id', 'title', 'url']
        const extendedPropsToUpdate = [
          'calendar',
          'guests',
          'location',
          'description'
        ]

        updateEventInCalendar(
          updatedEvent,
          propsToUpdate,
          extendedPropsToUpdate
        )
      })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(
    () => store.state.calendar.selectedCalendars
  )
  const rangeDate = computed(() => store.state.calendar.rangeDate)

  watch(selectedCalendars, () => {
    refetchEvents()
  })
  watch(rangeDate, () => {
    refetchEvents()
    updateCalendarTitle()
  })

  const updateCalendarTitle = () => {
    let title = ''
    const options = { day: 'numeric', month: 'short', year: 'numeric' }

    if (rangeDate.value) {
      if (rangeDate.value.includes(' to ')) {
        // Handle date range
        const [startStr, endStr] = rangeDate.value.split(' to ')
        const start = new Date(startStr)
        const end = new Date(endStr)

        const formattedStart = start.toLocaleDateString('en-GB', options)
        const formattedEnd = end.toLocaleDateString('en-GB', options)
        title = `${formattedStart} - ${formattedEnd}`
      } else {
        // Handle single date
        const date = new Date(rangeDate.value)
        title = date.toLocaleDateString('en-GB', options)
      }
    }

    // Update the title in the calendar toolbar
    document.querySelector('.fc-toolbar-title').innerText = title
  }

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    console.log('run fetch', info)
    console.log('run fetch[1]')
    // If there's no info => Don't make useless API call
    if (!info) return

    console.log('run fetch[2]')
    isLoadingFetch.value = true
    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
        rangeDate: rangeDate.value
      })
      .then((response) => {
        let riverData = []
        let landData = []
        try {
          const mappedDataRivers =
            response.data.vw_visitor_planning_area_rivers.rows.map((item) => {
              // Parse the `schedule_in` to a Date object
              const startDate = new Date(item.schedule_in)

              // Set the `end` date to one day after the start date
              const endDate = new Date(startDate)
              // Determine the number of hours to add based on badge_id
              let hoursToAdd
              switch (item.badge_id) {
                case 1:
                  hoursToAdd = 3
                  break
                case 2:
                  hoursToAdd = 2
                  break
                case 3:
                  hoursToAdd = 8
                  break
                case 4:
                  hoursToAdd = 7
                  break
                case 5:
                  hoursToAdd = 12
                  break
                default:
                  hoursToAdd = 0 // Default case if badge_id doesn't match any criteria
              }

              // Add the determined hours to the end date
              endDate.setHours(startDate.getHours() + hoursToAdd)
              let stateData = `{ IN Area : ${item.clock_in}}`
              if (item.clock_in === 'Invalid date') {
                stateData = `{ ON Planning }`
              }

              return {
                id: uuidv4(),
                data_id: item.id,
                url: '',
                title: `${item.boat_name} ⎰ Jumlah Crew Kapal ${item.total_person} ${stateData}`,
                start: startDate,
                end: null,
                allDay: false,
                extendedProps: {
                  calendar: '[ E ] Jalur Laut ( Crew Kapal )'
                },
                rawData: item
              }
            })
          riverData = mappedDataRivers
        } catch (error) {
          console.log('an error mapping river data', error)
        }
        try {
          const mappedData =
            response.data.vw_visitor_planning_area_lands.rows.map((item) => {
              // Parse the `schedule_in` to a Date object
              const startDate = new Date(item.schedule_in)

              // Set the `end` date to one day after the start date
              const endDate = new Date(startDate)
              // Determine the number of hours to add based on badge_id
              let hoursToAdd
              switch (item.badge_id) {
                case 1:
                  hoursToAdd = 3
                  break
                case 2:
                  hoursToAdd = 2
                  break
                case 3:
                  hoursToAdd = 8
                  break
                case 4:
                  hoursToAdd = 7
                  break
                case 5:
                  hoursToAdd = 12
                  break
                default:
                  hoursToAdd = 0 // Default case if badge_id doesn't match any criteria
              }

              // Add the determined hours to the end date
              endDate.setHours(startDate.getHours() + hoursToAdd)
              let stateData = `{ IN Area : ${item.clock_in}}`
              if (item.clock_in === 'Invalid date') {
                stateData = `{ ON Planning }`
              }

              return {
                id: uuidv4(),
                data_id: item.id,
                url: '',
                title: `${item.full_name} - ${item.badge_label} - ${
                  item.from_company
                } - ${item.ket ?? '-'} ${stateData}`,
                start: startDate,
                end: endDate,
                allDay: false,
                extendedProps: {
                  calendar: item.badge_label
                },
                rawData: item
              }
            })
          landData = mappedData
        } catch (error) {
          console.log('an error mapping land data', error)
        }

        console.log('landData', landData)
        console.log('riverData', riverData)

        isLoadingFetch.value = false
        successCallback([...landData, ...riverData])
        // successCallback(response.data)
      })
      .catch(() => {
        isLoadingFetch.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'listWeek',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    datesSet: () => {
      updateCalendarTitle() // Initialize title on the first render
    },
    events: fetchEvents,
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName =
        calendarsColor[calendarEvent._def.extendedProps.calendar]

      return [
        // Background Color
        `bg-light-${colorName} font-weight-bolder`
      ]
    },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value =
            !isCalendarOverlaySidebarActive.value
        }
      }
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(
        JSON.stringify(Object.assign(event.value, { start: info.date }))
      )
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isLoadingFetch = ref(false)
  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
    isLoadingFetch
  }
}
