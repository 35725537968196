import { render, staticRenderFns } from "./CalendarSidebar.vue?vue&type=template&id=793fa01e"
import script from "./CalendarSidebar.vue?vue&type=script&lang=js"
export * from "./CalendarSidebar.vue?vue&type=script&lang=js"
import style1 from "./CalendarSidebar.vue?vue&type=style&index=1&id=793fa01e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports