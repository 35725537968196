<template>
  <div>
    <Analytic />
  </div>
</template>
<script>
import Analytic from '@/views/monitoring/Analytic.vue'
import useJwt from '@/auth/jwt/useJwt'
export default {
  components: {
    Analytic
  },
  data() {
    return {
      timeoutDebounce: null,
      isLoadingScan: false,
      scanBuffer: '', // Temporary storage for barcode scanner input
      enterAudio: null,
      exitAudio: null,
      errorAudio: null
    }
  },
  mounted() {
    document.addEventListener('keypress', this.handleKeyPress)

    // Initialize the audio once when the component is mounted
    this.enterAudio = new Audio('/sal-enter-v2.mp3')
    this.exitAudio = new Audio('/sal-out-v2.mp3')
    this.errorAudio = new Audio('/sal-error-v1.mp3')
  },

  beforeDestroy() {
    // Remove event listener to avoid memory leaks
    document.removeEventListener('keypress', this.handleKeyPress)
  },

  methods: {
    playSound(state) {
      this.isLoadingScan = false
      console.log('playSound : ', state)
      switch (state) {
        case 'clock_in':
          console.log('[1] playSound : ', state)
          this.enterAudio.pause() // Pause any current playback
          this.enterAudio.currentTime = 0 // Reset playback position to the start
          this.enterAudio.play().catch((error) => {
            console.error('Audio playback failed:', error)
          })
          console.log('[end] playSound : ', state)
          break
        case 'clock_out':
          console.log('[2] playSound : ', state)
          this.exitAudio.pause() // Pause any current playback
          this.exitAudio.currentTime = 0 // Reset playback position to the start
          this.exitAudio.play().catch((error) => {
            console.error('Audio playback failed:', error)
          })
          console.log('[end] playSound : ', state)
          break
        case 'error':
          console.log('[3] playSound : ', state)
          this.errorAudio.pause() // Pause any current playback
          this.errorAudio.currentTime = 0 // Reset playback position to the start
          this.errorAudio.play().catch((error) => {
            console.error('Audio playback failed:', error)
          })
          console.log('[end] playSound : ', state)
          break

        default:
          break
      }
    },
    handleKeyPress(event) {
      const key = event.key.toLowerCase()
      const code = event.code.toLowerCase()
      const isDone = key === 'enter' || code === 'enter'
      if (isDone) {
        if (this.$can('scan-visitor', '')) {
          this.processQRCode(this.scanBuffer)
        } else {
          this.$router.push({ name: `ptsal-protections` })
        }
        this.scanBuffer = '' // Reset buffer after processing
      } else {
        // Add each keypress to the buffer (scanner sends characters as keystrokes)
        this.scanBuffer += event.key
      }
    },

    async executeQR(encrypted_key) {
      if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
      this.timeoutDebounce = setTimeout(
        () => this.startExecuteQR(encrypted_key),
        1000
      )
    },
    async startExecuteQR(encrypted_key) {
      /* hit x2 api
      1. cek qr
      2. action in or out */
      try {
        await useJwt.http
          .post('visitor/scanning/get', {
            encrypted_key: encrypted_key
          })
          .then(async (result) => {
            const visitor_qrcode = result.data.visitor_qrcode
            const visitor_public = result.data.visitor_public
            const visitor_private = result.data.visitor_private

            if (result.data.is_expired) {
              this.playSound('error')
              this.$swal({
                title: 'OOPS',
                html: `QRCode Expired`,
                timer: 2000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              return
            }
            if (result.data.is_allow_enter_area) {
              this.playSound('error')
              this.$swal({
                title: 'OOPS',
                html: `Pastikan QRCode Status masih Active`,
                timer: 2000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              return
            }

            if (visitor_qrcode.status.toLowerCase() === 'expired') {
              const message =
                'QRCode Sudah expired, Lakukan ReuseQR Terlebih dahulu untuk memasuki area PT SAL'
              this.playSound('error')
              this.$swal({
                title: 'EXPIRED',
                html: `${message}`,
                timer: 2000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            } else {
              let _userData = null
              if (visitor_public) {
                _userData = visitor_public
              }

              if (visitor_private) {
                _userData = visitor_private
              }
              let payload = {
                visitor_qrcode_id: visitor_qrcode.id,
                full_name: _userData.full_name,
                badge_id: _userData.badge_id,
                qrcode_link: visitor_qrcode.qrcode_link,
                sys_auto_renew: visitor_qrcode.sys_auto_renew,

                /* for notifications */
                token: this.$store.state.visitor.tokenNotification,
                model: visitor_qrcode.model,
                from_company: _userData.from_company
              }
              await useJwt.http
                .post('visitor/scanning/action', payload)
                .then(async (result) => {
                  console.log(result.data)
                  const {
                    action_success,
                    action,
                    full_name,
                    badge,
                    action_message
                  } = result.data
                  if (action_success === true) {
                    if (action === 'clock_in') {
                      this.playSound('clock_in')
                      this.$swal({
                        title: 'Clock IN Area',
                        html: `${full_name} Berhasil Memasuki Area PT SAL`,
                        timer: 1000,
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                      })
                    } else {
                      this.playSound('clock_out')
                      this.$swal({
                        title: 'Clock OUT Area',
                        html: `${full_name} Berhasil Keluar Area PT SAL`,
                        timer: 1000,
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                      })
                    }
                  } else {
                    this.playSound('error')
                    this.$swal({
                      title: 'OOPS',
                      html: `${full_name} ${action_message}`,
                      timer: 2000,
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary'
                      },
                      buttonsStyling: false
                    })
                  }
                })
            }
          })
          .catch((errors) => {
            this.playSound('error')
            this.$alert({
              title: `Tidak berhasil Memasuki Area PT SAL ${errors}`,
              variant: 'danger',
              icon: 'CheckIcon'
            })
          })
      } catch (error) {
        this.playSound('error')
        this.$alert({
          title: `An errors ${error}`,
          variant: 'danger',
          icon: 'CheckIcon'
        })
      }
    },
    async processQRCode() {
      await this.executeQR(this.scanBuffer)
      // Clear buffer after processing
      this.scanBuffer = ''
    },

    forceFocus() {
      const searchInput = this.$refs.searchInput.$el.querySelector('input')
      if (searchInput) {
        searchInput.focus()
      }
    }
  }
}
</script>
