<template>
  <div
    class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"
  >
    <div class="p-2">
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        Add Event
      </b-button> -->
      <b-form-group
        label="Data Planning Show By Range Date"
        label-for="range_id"
      >
        <flat-pickr
          id="range_id"
          v-model="rangeDate"
          class="form-control"
          :config="{ mode: 'range' }"
        />
      </b-form-group>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendars Planning</span>
        </h5>
        <b-form-checkbox v-model="checkAll" class="mb-1" >
          All <b-badge>{{ analytics.all.on_plan }}</b-badge> <b-badge variant="success">
            {{ analytics.all.already_in_area }}</b-badge
          >
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"

            >
              {{ item.label }} <b-badge variant="secondary">{{ analytics[getKeyFromLabel(item.label)].on_plan }}</b-badge> <b-badge variant="success">{{
                analytics[getKeyFromLabel(item.label)].already_in_area
              }}</b-badge>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      <hr />
      <strong>Keterangan : </strong><br />
      <b-badge>Total Rencana</b-badge> <b-badge variant="success">Total Already IN Area</b-badge>
      <br/>
      Total Rencana dan Total Already IN Area Berdasarkan Waktu <b-badge variant="danger" class="badge-glow button">Schedule IN </b-badge>
      <hr />
      Default <strong>Schedule IN OUT</strong> : <br />
      <small>
        <strong>A1</strong> : 3 Hours, <strong>A2</strong> : 2 Hours,
        <strong>B</strong> : 8 Hours, <strong>C</strong> : 7 Hours, &
        <strong>D</strong> : 12 Hours
      </small>
      <!-- <hr /> -->
      <!-- Get insights at your fingertips. Our system offers unparalleled visibility
      into visitor flow and behavior, turning raw data into actionable
      intelligence for PT Sarana Abadi Lestari. -->
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'
import flatPickr from 'vue-flatpickr-component'

export default {
  directives: {
    Ripple
  },
  components: {
    flatPickr,
    BBadge,
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true
    }
  },
  setup() {
    const {
      analytics,
      calendarOptions,
      selectedCalendars,
      checkAll,
      rangeDate
    } = useCalendarSidebar()

    return {
      analytics,
      calendarOptions,
      selectedCalendars,
      checkAll,
      rangeDate
    }
  },
  methods: {
    // Helper function to extract the key (a1, a2, b, etc.) from the label
    getKeyFromLabel(label) {
      switch (label) {
        case '[ A.1 ] Tamu ( Visitor ) - Umum':
          return 'a1'
        case '[ A.2 ] Awak 2 Truck':
          return 'a2'
        case '[ B ] Identitas / Karyawan / Rutinitas':
          return 'b'
        case '[ C ] Pekerja Harian':
          return 'c'
        case '[ D ] Pengurus Agent BBM':
          return 'd'
        case '[ E ] Jalur Laut ( Crew Kapal )':
          return 'e'
        default:
          return 'all' // Default for unknown cases or '[ E ]'
      }
    }
  }
}
</script>

<style></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
